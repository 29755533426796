<template>
	<div :class="['blog-grid', `grid-${articles.length}`]">
		<skeleton-loader class="grid-item " v-for="(article, i) in articles" :key="i"  :isVisible="loading">
			<base-article
				:imageSize="imageSize(i)"
				:article="article"
			/>
		</skeleton-loader>
	</div>
</template>

<script>
import BaseArticle from '../BaseArticle'
import SkeletonLoader from '~/website/front/components/molecules/loaders/SkeletonLoader'

export default {
	props: {
		articles: Array,
		isEven: Boolean,
		loading: Boolean
	},
	components: {
		BaseArticle,
		SkeletonLoader
	},
	methods: {
		imageSize (index) {
			const isFullChunkAndIsEven = this.articles.length > 2 && this.isEven && index === 0
			const isFullChunkAndIsOdd = this.articles.length > 2 && !this.isEven && index === 2
			const isLesserChunk = this.articles.length <= 2

			if (isFullChunkAndIsEven || isFullChunkAndIsOdd || isLesserChunk) return 'lg'
			return 'sm'
		}
	}
}

</script>
<style lang="scss" scoped>
.safari .blog-grid .grid-item :deep(.article) {
	height: -webkit-stretch;
	height: stretch;
}
	.blog-grid{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 2rem;

		.grid-item :deep(.article)  {
			height: 100%;
		}

		@include media-breakpoint-up(md){
			gap: 3rem;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 2rem;
			grid-template-areas:
			"a b c"
		}
		@include media-breakpoint-up(xl) {
			grid-template-columns: repeat(2, 1fr);
			grid-template-areas:
			"a b"
			"a c";
		}

		.grid-item{
			width: 100%;
			min-height: 214px;

			@include media-breakpoint-up(lg) {
				margin-right: 1rem;
				&:nth-child(2) {
					margin-right: 0;
				}
			}
			@include media-breakpoint-up(lg) {
				margin-right: .4rem;
				&:nth-child(2) {
					margin-right: .4rem;
				}
				&:nth-child(3) {
					margin-right: 0;
					display: block;
				}
			}
			@include media-breakpoint-up(xl){
				margin: 0;
			}
		}
	}
	.grid-3{
		@include media-breakpoint-up(sm) {
			.grid-item:nth-of-type(1){
				grid-area: a;
			}
			.grid-item:nth-of-type(2){
				grid-area: b;
			}
			.grid-item:nth-of-type(3){
				grid-area: c;
			}
		}
	}
	.grid-3:nth-of-type(2n){
		@include media-breakpoint-up(sm) {
			grid-template-areas:
			"a c"
			"b c";
		}
	}
	.grid-2{
		grid-template-areas: "a b"
		"a b";
		.grid-item:nth-of-type(1){
			grid-area: a;
		}
		.grid-item:nth-of-type(2){
			grid-area: b;
		}
	}
	.grid-1 .grid-item{
		grid-area: a;
	}
</style>
