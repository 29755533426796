<template>
	<article-manager
		:show-article-inital="value.articlePerPage"
		v-slot="{
			numberOfPages,
			currentPage,
			changePage,
			loading
		}">

		<section class="section">
			<base-container>
				<base-font variant="section-header">{{value.mainTitle}}</base-font>
				<article-wrapper v-for="(items, index) in chunks" :isEven="isEven(index)" :loading="loading" :key="items[0].alias" :articles="items"/>
				<div class="product-list-navigation">
					<base-pagination v-if="value.pagination" :pages="numberOfPages" :currentPage="currentPage" @page-change="changePage"/>
				</div>
			</base-container>
		</section>
	</article-manager>
</template>
<script>
import ArticleManager from '~/blog/front/components/ArticleManager'
import BasePagination from '~/website/front/components/organisms/BasePagination'

import ArticleWrapper from '../../../components/ArticleWrapper'
import { chunkArray } from 'utils/array'
export default {
	computed: {
		articles () {
			return this.$app.page.articles
		},
		chunks () {
			return chunkArray(this.articles, 3)
		},
		value () {
			return this.$app.page.value
		}
	},
	methods: {
		isEven (index) {
			return index % 2 === 0
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('articles')
	},
	components: {
		ArticleWrapper,
		ArticleManager,
		BasePagination
	}
}
</script>
<style lang="scss" scoped>
.section {
	min-height: 480px;
}

.product-list-navigation {
	display: flex;
	justify-content: flex-end;
}
</style>
