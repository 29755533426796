import { Page } from '~/cms/front/core/pages/Page'
import component from './ArticleList.vue'

class ArticleListPage extends Page {
	static alias = 'article-list'
	component = component
	articles = Array(3).fill({})

	get header () {
		return {
			title: this.value.title
		}
	}
}

export { ArticleListPage }
