function chunkArray (array, length) {
	const chunks = []
	let i = 0
	const n = array.length

	while (i < n) {
		chunks.push(array.slice(i, i += length))
	}

	return chunks
}

export { chunkArray }
