<script>
import { Article } from '~/blog/front/core/entities/Article'
import { serialize } from 'utils/strings'

export default {
	props: {
		showArticleInital: {
			type: Number,
			default: 8
		}
	},
	data: () => ({
		loading: true,
		numberOfPages: 1,
		currentPage: 1,
		location: {}
	}),
	methods: {
		async fetchArticles () {
			const fetchOptions = {
				app: this.$app,
				take: this.showArticleInital,
				skip: this.currentPage * this.showArticleInital - this.showArticleInital
			}
			const { numberOfAllArticles } = await Article.fetch(fetchOptions)
			this.numberOfPages = Math.ceil(numberOfAllArticles / this.showArticleInital)
		},
		async changePage (page) {
			this.currentPage = page

			this.updatePageQuery()

			await this.fetchArticles()

			window.scroll({ top: 0, left: 0, behavior: 'smooth' })
		},

		loadSettingsFromQuery () {
			const { page } = this.$route.query
			if (page) this.currentPage = Number(page)
		},
		updatePageQuery () {
			const query = {
				page: this.currentPage
			}

			history.replaceState(
				{},
				null,
				this.$route.path + serialize(query)
			)
		}
	},
	async mounted () {
		this.loadSettingsFromQuery()

		await this.fetchArticles()

		this.loading = false
	},
	render () {
		return this.$scopedSlots.default({
			loading: this.loading,
			numberOfPages: this.numberOfPages,
			currentPage: this.currentPage,
			changePage: this.changePage
		})
	}
}
</script>
